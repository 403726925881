@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap");

:root {
  --color-sand: #f2ab87;
  --color-green: #051f1f;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  position: relative;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  min-height: 100vh;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(204, 204, 204, 1) 100%);
  background-color: #fff;
  color: var(--color-green);
  overflow-x: hidden;
  scroll-behavior: smooth;
}
body::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-image: url(./assets/clouds.webp);
  background-size: 110% auto;
  background-position: 0% -50px;
  background-repeat: repeat-x;
  pointer-events: none;
  z-index: 1;
  animation: gradient-animation 120s linear infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% -50px;
  }
  100% {
    background-position: 3300% -50px;
  }
}

.inset-line {
  position: relative;
}
.inset-line::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border: 1px solid var(--color-green);
  pointer-events: none;
}
.inset-line.line-orange::after {
  border-color: var(--color-sand);
}

.content-overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(204, 204, 204, 1) 70%);
  z-index: 4;
}
.content-overlay.show {
  display: block !important;
}

#menu-wrapper {
  z-index: 5;
  top: 40px;
  transition: 0.3s ease-out;
}
#menu-wrapper.to-front {
  z-index: 9;
  top: 20px;
}
#menu-wrapper.to-front .menu {
  @apply shadow-lg;
}

.menu {
  padding: 1rem 4rem !important;
  background-color: var(--color-sand);
}
.menu .burger {
  @apply hover:drop-shadow;
  fill: var(--color-green);
  transition: 0.2s ease;
}
.menu .burger:hover {
  fill: #fff;
}
.menu nav ul li a {
  @apply hover:drop-shadow;
  display: block;
  padding: 0.75rem;
  color: var(--color-green);
  text-transform: uppercase;
  font-weight: 800;
  overflow: hidden;
  transition: 0.1s ease-out;
}
.menu nav ul li a:hover {
  color: #fff;
  text-shadow: 0px 0px 10px rgba(5, 31, 31, 0.25);
}

.content-box {
  position: relative;
  color: #b4bcbc;
  background-image: url(./assets/content-bg.webp);
  z-index: 2;
}

.products .product {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-sand);
  padding: 20px 8px 0 8px;
  transform: translateY(0);
  transition: 0.2s ease-out;
}
.products .product:hover {
  @apply shadow-lg;
  transform: translateY(-15px);
}
.products .product h3 {
  font-size: 0.9rem;
  font-weight: 800;
}
.products .product img {
  height: 130px;
  margin-left: auto;
  margin-right: auto;
}

.contact-us svg {
  display: inline-block;
  margin-right: 0.5rem;
  fill: var(--color-green);
}

.contact-us form.sent input,
.contact-us form.sent textarea,
.contact-us form.sent button {
  opacity: 0.5;
  pointer-events: none;
}

.foot {
  background-image: url(./assets/foot.svg);
  background-size: 100% auto;
  background-position: bottom center;
  background-repeat: no-repeat;
}
.powered-by {
  padding: 1.25rem 0;
  background-color: var(--color-green);
  color: var(--color-sand);
  text-align: center;
  font-size: 0.8rem;
  letter-spacing: 2px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  @apply placeholder-[#051f1f];
  position: relative;
  display: block;
  width: 100%;
  background-color: var(--color-sand);
  border: 1px solid var(--color-sand);
  border-radius: 40px;
  margin-bottom: 0.5rem;
  padding: 1rem 2rem;
  font-weight: 500;
  outline: none;
  transition: 0.2s ease-out;
}
input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
textarea:focus {
  border: 1px solid var(--color-green);
  box-shadow: 0px 0px 25px 0px rgba(5, 31, 31, 0.24);
  -webkit-box-shadow: 0px 0px 25px 0px rgba(5, 31, 31, 0.24);
  -moz-box-shadow: 0px 0px 25px 0px rgba(5, 31, 31, 0.24);
}

button {
  @apply hover:brightness-125;
  background: var(--color-green) !important;
  color: var(--color-sand);
  padding: 0.75rem 2rem;
  border-radius: 40px;
  transition: 0.2s ease-out;
}
button:hover {
  box-shadow: 0px 0px 25px 0px rgba(5, 31, 31, 0.24);
  -webkit-box-shadow: 0px 0px 25px 0px rgba(5, 31, 31, 0.24);
  -moz-box-shadow: 0px 0px 25px 0px rgba(5, 31, 31, 0.24);
}

h1 {
  font-size: 3.5rem;
  line-height: 4.2rem;
  font-weight: 900;
}

h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  color: var(--color-sand);
  text-align: left;
  margin-bottom: 1rem;
}

h3 {
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 700;
  color: var(--color-green);
}

.container {
  padding-left: 5px;
  padding-right: 5px;
}
p {
  margin-bottom: 1rem;
}

.color-sand {
  color: var(--color-sand);
}
.color-green {
  color: var(--color-green);
}
.bg-sand {
  background-color: var(--color-sand);
}
.bg-darkgreen {
  background-color: var(--color-green);
}

@media (min-width: 1536px) {
  .container {
    max-width: 1280px;
  }
}

/***** MAX-WIDTH *****/

@media (max-width: 1280px) {
  h1 {
    font-size: 2.5rem;
    line-height: 2.75rem;
  }
  h2 {
    font-size: 1.3rem;
    line-height: 1.65rem;
  }

  .products .product {
    padding: 20px 8px 15px 8px;
  }
  .products .product h3 {
    font-size: 0.8rem;
  }
}

@media (max-width: 1024px) {
  h1 {
    font-size: 2rem;
    line-height: 2.25rem;
  }
}

@media (max-width: 768px) {
  #menu-wrapper {
    top: 10px !important;
    padding: 0 5px;
    z-index: 9;
  }
  .menu {
    padding: 1rem 2rem !important;
  }
  .menu .logo {
    width: 120px;
  }
  .menu nav {
    max-height: 0px;
    overflow: hidden;
    transition: 0.4s ease-out;
  }
  .menu.open nav {
    max-height: 120px;
  }
  .menu nav ul li a {
    padding: 0.25rem;
    text-align: center;
  }
}

@media (max-width: 640px) {
  body {
    font-size: 0.9rem;
  }
  body::after {
    background-size: 210% auto;
    animation: gradient-animation 240s linear infinite;
  }

  h1 {
    font-size: 2rem;
    line-height: 2.25rem;
  }
  .offpage h1 {
    font-size: 1.5rem;
    line-height: 1.75rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  h2 {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  .menu .logo {
    width: 80px;
  }

  .products .product h3 {
    font-size: 0.7rem;
  }

  .text-sm {
    font-size: 0.7rem;
  }
}
